import React, { useState } from 'react'

const ToggleVisibility = ({ 
    showLabel = 'Show',
    hideLabel = 'Hide',
    initialValue = false,
    children
}) => {
    const [show, setShow] = useState(initialValue)

    const toggleVisibility = () => {
        setShow(!show)
    }

    const toggleButton = () => {
        return (
            <React.Fragment>
                <button
                    type="button"
                    onClick={toggleVisibility}
                    className="m-3 rounded-md bg-oxford-blue py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                    {show}
                    {show ? hideLabel : showLabel}
                </button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div 
                onClick={toggleVisibility}
                className={`transition ease-in-out delay-150 fixed w-full h-full top-0 left-0 flex items-center justify-center ${show ? 'visible opacity-100' : 'invisible opacity-0'}`}>
                <div className={`fixed w-full h-full top-0 left-0 flex items-center justify-center bg-red-pantone opacity-75`}></div>
                <div className={`fixed w-full h-full top-0 left-0 flex items-center justify-center z-50`}>
                    <div className="flex flex-col items-center justify-center">
                        {children}
                        {toggleButton(toggleVisibility)}
                    </div>
                </div>
            </div>
            {toggleButton(toggleVisibility)}
        </React.Fragment>
    )
}

export default ToggleVisibility;