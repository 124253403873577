import React, { useState, useRef } from 'react';
import useAnimationFrame from './useAnimationFrame';
import { BuyUpgradesList, BuyWorkersList } from './Worker';
import { KeyboardIcon } from '../components/svg/Icons';

function initState() {
    const previousSession = loadSession();
    if (previousSession == null) {
        return defaultGameValues();
    }
    else {
        return previousSession;
    }
}

// TODO: Generate counters from workers?
// TODO: Handle "upgrade" when adding a new worker. version updates
// TODO: increase cost of workers per owned
function defaultGameValues() {
    return {
        counters: {
            lines: 0,
            click: 0,
            page: 0,
            plugin: 0,
            website: 0,
            eshop: 0,
            app: 0,
            hosting: 0,
        },
        upgrades:   {
            click: 0,
            page: 0,
            plugin: 0,
            website: 0,
            eshop: 0,
            app: 0,
            hosting: 0,
        },
        fps: 30,
    };
}

const  workers = [
    {
        type: 'page',
        name: 'Page',
        price: 25,
        cps: 0.1,
        description: "Create a new page.",
    },
    {
        type: 'plugin',
        name: 'Plugin',
        price: 500,
        cps: 1,
        description: "Make a custom plugin.",
    },
    {
        type: 'website',
        name: 'Website',
        price: 1000,
        cps: 10,
        description: "Publish a website.",
    },
    {
        type: 'eshop',
        name: 'eCommerce Shop',
        price: 10000,
        cps: 100,
        description: "Create an e-commerce shop.",
    },
    {
        type: 'app',
        name: 'App',
        price: 100000,
        cps: 1000,
        description: "Create a mobile app.",
    },
    {
        type: 'hosting',
        name: 'Web Hosting',
        price: 100000000,
        cps: 100000,
        description: "Start your own hosting company.",
    }
]

const upgrades = [
    {
        type: 'click',
        levels: [
            {
                level:1,
                name:"Designer",
                short_description:"The Pixel Perfectionist",
                requirement: 15,
                price: 25,
                modifier: 2,
                description:"This meticulous designer can create stunning graphics that will leave your users in awe."
            },	
            {
                level:2,
                name:"Coder",
                short_description:"The Code Cruncher",
                requirement: 50,
                price: 100,
                modifier: 5,
                description:"This efficient coder can crank out lines of code faster than anyone else."
            },
            {
                level:3,
                name:"Programmer",
                short_description:"The Bug Buster",
                requirement: 150,
                price:150,
                modifier:10,
                description:"This skilled programmer can find and squash bugs in your code with lightning speed."
            },
            {
                level:4,
                name:"Hacker",
                short_description:"The Code Ninja",
                requirement: 500,
                price:500,
                modifier:20,
                description:"This master hacker can penetrate any system and retrieve the data you need."
            },
            {
                level:5,
                name:"Database Administrator",
                short_description:"The Data Dynamo",
                requirement: 1500,
                price:1500,
                modifier:50,
                description:"This knowledgeable database administrator can organize your data and optimize your queries."
            },
            {
                level:6,
                name:"Front-End Developer",
                short_description:"The UI Unicorn",
                requirement: 5000,
                price:3000,
                modifier:100,
                description:"This creative front-end developer can design and build beautiful and responsive user interfaces."
            },
            {
                level:7,
                name:"Back-End Developer",
                short_description:"The Server Samurai",
                requirement: 15000,
                price:5000,
                modifier:200,
                description:"This expert back-end developer can configure and maintain servers that can handle any traffic load."
            },
            {
                level:8,
                name:"DevOps Engineer",
                short_description:"The Deployment Duke",
                requirement: 50000,
                price:50000,
                modifier:500,
                description:"This skilled DevOps engineer can automate your deployment process and ensure your apps are always up and running."
            },
            {
                level:9,
                name:"Quality Assurance Engineer",
                short_description:"The Bug Hunter",
                requirement: 15000000,
                price:10000,
                modifier:1000,
                description:"This meticulous quality assurance engineer can test your app and find even the most elusive bugs."
            },
            {
                level:10,
                name:"AI",
                short_description:"Just your friendly neighborhood AI",
                requirement: 50000000,
                price:500000,
                modifier:10000,
                description:"I'm sorry Dave, I'm afraid I can't do that.."
            }
        ]
    },
    {
        type: 'page',
        levels: [
            {
                level: 1,
                name: 'Analytics',
                requirement: 5,
                price: 250,
                modifier: 1.5,
                description: "Measure performance of your website.",
            },
            {
                level: 2,
                name: 'Add a slider',
                requirement: 10,
                price: 2000,
                modifier: 5,
                description: "Now your board of directors can each have their say.",
            },
        ]
    }
]

function saveSession(state){
    localStorage.setItem('session', JSON.stringify(state));
} 
  
function loadSession(){
    return localStorage.getItem('session') !== undefined ? JSON.parse(localStorage.getItem('session')) : null;
}

const useAwake = (callBack = () => {}) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}
  

const Clicker = () => {
    const [currentValues, setCurrentValues] = useState(() => initState());
    const tickTimeRef = useRef(0);
    const [effect, setEffect] = useState(false);

    useAwake(() => {
        saveSession(currentValues);
    }, [currentValues]);

    const addLines = (amount) => {

        // TODO: Solve race condition...
        let newLines = currentValues.counters.lines + amount;
        let newCounters = {
            ...currentValues.counters,
            lines: newLines,
        }

        setCurrentValues(previousValues => ({...previousValues, counters: newCounters}));
    }

    const addClick = (amount) => {
        // Check if click is upgraded...
        let click_level = currentValues.upgrades.click;
        let modifier = 1;
        if (click_level > 0) {
            modifier = upgrades.find(u => u.type === 'click').levels.find(l => l.level === click_level).modifier;
        }
        amount *= modifier;

        let newClick = currentValues.counters.click + amount;
        let newLines = currentValues.counters.lines + amount;
        let newCounters = {
            ...currentValues.counters,
            lines: newLines,
            click: newClick,
        }
        setCurrentValues(previousValues => ({ ...previousValues, counters: newCounters}));
    }

    const incrementClick = (event) => {

        //console.log(event);

        if (event.clientX > 0 && event.clientY > 0) {
            addClick(1);
            setEffect(true);
        }
    }

    const renderClickPower = () => {
        let click_level = currentValues.upgrades.click;
        let total_click_power = 1;
        let s = '';
        let name = '';
        if (click_level > 0) {
            total_click_power = upgrades.find(u => u.type === 'click').levels.find(l => l.level === click_level).modifier
            name = upgrades.find(u => u.type === 'click').levels.find(l => l.level === click_level).name;
            s = 's';
        }

        return (
            <>{total_click_power} Line{s} of Code <span className={`block ${(name) ? 'visible': 'hidden'}`}>[{name}]</span></>
        )
    }

    useAnimationFrame((deltaTime) => {
        tickTimeRef.current = tickTimeRef.current + (deltaTime*1000);

        // TODO: Run a save every certain number of frames...
        // TODO: Calculate fps, and also only run every second...
        if (tickTimeRef.current >= 1000) {
            tickTimeRef.current = 0;

            let tickIncrement = 0;

            // Iterate over workers
            Object.keys(currentValues.counters).forEach((counter) => {
                if (currentValues.counters[counter] > 0 && counter !== 'lines' && counter !== 'click') {
                    let worker_cps = workers.find(w => w.type === counter).cps;

                    // Upgrade check...
                    if(currentValues.upgrades[counter] > 0) {
                        worker_cps = worker_cps * upgrades.find(u => u.type === counter).levels.find(l => l.level === currentValues.upgrades[counter]).modifier
                    }


                    // Worker Incrementer
                    tickIncrement = tickIncrement + (currentValues.counters[counter] * worker_cps);
                }
            });

            if (tickIncrement > 0) {
                addLines(tickIncrement);
            }

            // save...
            saveSession(currentValues);
        }
    }, [currentValues, addLines]);

    const buyWorker = (type, price) => {
        if(currentValues.counters.lines >= price) {
            // Increment workers, subtract lines
            let newCounters = {
                ...currentValues.counters,
                lines: currentValues.counters.lines - price,
                [type]: currentValues.counters[type] + 1,
            }
            setCurrentValues(previousValues => ({...previousValues, counters: newCounters}));
        }
    }

    const buyUpgrade = (type, level, price) => {
        if(currentValues.counters.lines >= price) {
            // Increment levels, subtract lines
            let newCounters = {
                ...currentValues.counters,
                lines: currentValues.counters.lines - price,
            }
            let newUpgrades = {
              ...currentValues.upgrades,
                [type]: level,
            }
            setCurrentValues(previousValues => ({...previousValues, counters: newCounters, upgrades: newUpgrades}));
        }
    }


    const save = () => {
        saveSession(currentValues);
    }

    const reset = () => {
        localStorage.removeItem('session');
        setCurrentValues(defaultGameValues());
        console.log('Reset game values.');
    }

    function StatItem(props) {
        const { children } = props;

        return (
            <p className="justify-center py-1.5 px-2.5 text-sm text-white">
                {children}
            </p>
        )
    }

    function StatItemLarge(props) {
        const { children } = props;

        return (
            <p className="justify-center py-1.5 px-2.5 text-lg font-bold text-white shadow-sm border-b border-rose-400">
                {children}
            </p>
        )
    }


    function RenderWorkerStats() {
        return workers.map((worker, index) => {
            if (currentValues.counters[worker.type] > 0) {
                return (
                    <StatItem key={index}>
                        {worker.name}s: {currentValues.counters[worker.type]}
                    </StatItem>
                );
            }

            return null;
        });
    }

    function RenderUpgrades() {
        return upgrades.map((upgrade) => {

            let current_level = currentValues.upgrades[upgrade.type];

            return upgrade.levels.map((level, index) => {
                if (current_level === level.level && upgrade.type !== 'click') {
                    return (
                        <StatItem key={index}>
                            [{upgrade.type} upgrade] {level.name} - Rate * {level.modifier}
                        </StatItem>
                    );
                }

                return null;
            });
        });
    }

    function RenderLineStats() {

        let totalCps = 0;

        // TODO: Iterate over workers
        Object.keys(currentValues.counters).forEach((counter) => {
            if (currentValues.counters[counter] > 0 && counter !== 'lines' && counter !== 'click') {
                let worker_cps = workers.find(w => w.type === counter).cps;

                    // Upgrade check...
                    if(currentValues.upgrades[counter] > 0) {
                        worker_cps = worker_cps * upgrades.find(u => u.type === counter).levels.find(l => l.level === currentValues.upgrades[counter]).modifier
                    }
                totalCps = totalCps + (currentValues.counters[counter] * worker_cps);
            }
        });

        return (
            <>
                <StatItemLarge>
                    <span className='block'>
                        Uncommitted lines of code: 
                    </span>
                    {currentValues.counters.lines.toFixed(2)}
                </StatItemLarge>
                <StatItem>
                    Lines per second: 
                    <span className='font-bold'>
                        &nbsp;{totalCps.toFixed(2)}
                    </span>
                </StatItem>
                <StatItem>
                    Total lines clicked: 
                    <span className='font-bold'>
                        &nbsp;{currentValues.counters.click}
                    </span>
                </StatItem>
            </>
        );
    }

    function RenderGameControls() {

        return (
            <div className="flex flex-row lg:w-96 my-3 space-x-5">
                <button
                    onClick={save}
                    className="grow h-14 rounded-md bg-avocado py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm">
                        Save Session
                </button>
                <button
                    onClick={reset}
                    className="grow h-14 rounded-md border border-rose-400 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm">
                        Reset Game
                </button>
            </div>
        )
    }

    // https://www.svgrepo.com/collection/majesticons-solid-interface-icons/
    // TODO: sticky - https://mtm.dev/sticky-stuck-styles
    return (
        <div className="w-full flex flex-col-reverse lg:flex-row">
            <div className="flex flex-col grow justify-end items-stretch lg:items-center">
                <div className="flex flex-col lg:w-96 m-3 bg-rose-500 rounded-lg">
                    <RenderLineStats />
                    <RenderWorkerStats />
                    <RenderUpgrades />
                </div>
                <div className="flex lg:w-96 flex-col m-3 items-stretch lg:items-center sticky bottom-0 bg-red-pantone">
                    <button
                        onClick={incrementClick}
                        onAnimationEnd={() => setEffect(false)}
                        className={`${effect && "animate-wiggle"} flex flex-col lg:w-96 h-24 mb-3 border-2 items-center bg-rose-500 active:bg-rose-600 rounded-md py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm`}>
                            <KeyboardIcon />
                            {renderClickPower()}
                    </button>
                    <RenderGameControls />
                </div>
            </div>
            <div className="flex lg:w-96 flex-col overflow-y-auto">
                <BuyWorkersList
                    workers={workers}
                    onBuy={buyWorker}
                    currentValues={currentValues} />
                <BuyUpgradesList
                    upgrades={upgrades}
                    onBuy={buyUpgrade}
                    currentValues={currentValues} />
            </div>
        </div>
    );
}

export default Clicker;