export function BuyWorkersList({
  workers,
  onBuy,
  currentValues
}) {
  return (
    <ul className="flex flex-col">
      {workers.map((worker) => (
        <BuyWorkerContainer
          key={worker.type}
          worker={worker}
          onBuy={() => onBuy(worker.type, worker.price)}
          currentValues={currentValues} />
      ))}
    </ul>
  )
}

export function BuyWorkerContainer({
  worker,
  onBuy,
  currentValues
}) {

  let canBuy = currentValues.counters['lines'] >= worker.price;
  let canSee = (currentValues.counters['click'] >= worker.price || currentValues.counters[worker.type] > 0);

  return (
    <li className={`flex flex-1 rounded-lg border-2 border-rose-500 bg-rose-400 shadow m-3 ${(canBuy || canSee) ? 'visible' : 'hidden'}`}>
      <div className="flex w-full items-center justify-between space-x-3 p-3">
        <div className="flex flex-col flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-white">{worker.name}</h3>
            <span className="inline-block flex-shrink-0 rounded-full bg-avocado px-2 py-0.5 text-xs font-medium text-white">
              Cost: {worker.price} lines
            </span>
          </div>
          <p className="mt-1 text-xs text-white">
            {worker.description}
          </p>
          <div className="mt-1 flex flex-1 text-xs text-rose-600">
            Total owned {currentValues.counters[worker.type]}
          </div>
        </div>
      </div>

      <div className={`w-32 flex flex-row ${(canBuy) ? 'visible' : 'hidden'}`}>
        <div className="flex flex-1">
          <BigButton handleClick={onBuy} label="Buy +1" />
        </div>
      </div>
    </li>
  )
}

export function BigButton({
  handleClick,
  label
}) {
  return (
      <>
        <button
          onClick={handleClick}
          className="grow justify-center border-l border-rose-300 hover:bg-rose-500 active:bg-rose-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm">
          {label}
        </button>
      </>
  );
}

// TODO: Restrict upgrades by requirement AND price...

export function BuyUpgradesList({
  upgrades,
  onBuy,
  currentValues
}) {
  return (
    <ul className="flex flex-col">
      {upgrades.map((worker, i) => (
        worker.levels.map((level, ii) => (
        <BuyUpgrade
          key={`${i}_${ii}`}
          level={level}
          worker={worker}
          onBuy={() => onBuy(worker.type, level.level, level.price)}
          currentValues={currentValues} />
      ))))}
    </ul>
  )
}

export function BuyUpgrade({
  level,
  worker,
  onBuy,
  currentValues
}) {

  let upgrades = currentValues.upgrades;
  let counters = currentValues.counters;

  let canBuy = counters['lines'] >= level.price;
  let canLevel = upgrades[worker.type] + 1 === level.level;
  let workerSee = (worker.type !== 'click' && counters[worker.type] >= level.requirement);
  let clickerSee = (worker.type === 'click' && counters['click'] >= level.price);
  let isVisible = canBuy || workerSee || clickerSee;

  return (
    <li className={`flex flex-1 divide-y divide-rose-400 rounded-lg border-2 border-rose-500 bg-rose-400 shadow m-3
      ${(isVisible && canLevel) ? 'visible' : 'hidden'}`}>
      <div className="flex flex-shrink w-full items-center justify-between space-x-3 p-3">
        <div className="flex flex-col flex-1">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-white">{level.name}</h3>
            <span className="inline-block flex-shrink-0 rounded-full bg-avocado px-2 py-0.5 text-xs font-medium text-white">
              Cost: {level.price} lines
            </span>
          </div>
          <p className="mt-1 text-xs text-white">
            {level.description}
          </p>
        </div>
      </div>

      <div className={`w-32 flex flex-row ${(canBuy) ? 'visible' : 'hidden'}`}>
        <div className="flex flex-1">
          <BigButton handleClick={onBuy} label="Upgrade" />
        </div>
      </div>

    </li>
  )
}