import { useRef, useLayoutEffect } from 'react';

const useAnimationFrame = (callback) => {
    const callBackRef = useRef(callback);
    const frameRef = useRef();
    const timerRef = useRef();

    useLayoutEffect(() => {
        callBackRef.current = callback;
    }, [callback]);

    useLayoutEffect(() => {
        const loop = (timestamp) => {
            frameRef.current = requestAnimationFrame(loop);

            let dt = 0;
            if (timerRef.current !== undefined && timerRef.current !== null)
                dt = timestamp - timerRef.current;

            const callBack = callBackRef.current;
            callBack(dt/1000);

            timerRef.current = timestamp;
        };

        frameRef.current = requestAnimationFrame(loop);
        return () => cancelAnimationFrame(frameRef.current);
        
    }, []);
}

export default useAnimationFrame