export const KeyboardIcon = ({
    color = "currentColor",
}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path fill={color} fillRule="evenodd" d="M4 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4zm6-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4zm3-3a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1zm5-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H18zm-9 1a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1zM6 9a1 1 0 0 0 0 2h.01a1 1 0 1 0 0-2H6zm-1 5a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1zm13-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H18z" clipRule="evenodd"></path>
    </svg>
)

export const GenericIcon = ({
    color = "currentColor",
}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path fill={color} fillRule="evenodd" d="M2 6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6zm5 1a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2zm-8 4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2H7zm4 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6zm-4 4a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H7zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2z" clipRule="evenodd"></path>
    </svg>
)