import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const TOKEN = process.env.REACT_APP_GQL_TOKEN;
const URI = process.env.REACT_APP_GQL_URI;

function getLink() {
    let httpLink = createHttpLink({
        uri: URI,
    });

    let authLink = setContext((_, { headers }) => {
        return {
          headers: {
          ...headers,
            authorization: 'Bearer ' + TOKEN,
          },
        }
      });

    return authLink.concat(httpLink);
}

export function getApolloClient() {
    let client = new ApolloClient({
        link: getLink(),
        cache: new InMemoryCache(),
    });

    return client;
}