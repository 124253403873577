export function GridContainer({children}) {
    return (
        <div className="grid grid-cols-1 divide-y divide-gray-200 rounded-lg border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
            {children}
        </div>
    )
  }
  
export function GridElement({
    children,
    title
}) {
    return (
        <div className="px-6 py-5 text-center text-lg font-medium">
            <span className="text-gray-900 invisible">1</span>
            <span className="text-gray-600">
                {title}
            </span>
            {children}
        </div>
    )
}