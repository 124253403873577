import './App.css';
import { useQuery, gql } from '@apollo/client';
import ToggleVisibility from './utility/ToggleVisibility';
import Clicker from './utility/Clicker';
import { GridContainer, GridElement } from './utility/Grids';

const GET_HELLO = gql`
  query MyQuery {
    helloEntries {
      ... on hello_hello_Entry {
        id
        contentMain {
          ... on contentMain_text_BlockType {
            id
            text
          }
          ... on contentMain_image_BlockType {
            id
            image {
              img
            }
          }
        }
      }
    }
  }
`;

function Hello() {
  const { loading, error, data } = useQuery(GET_HELLO);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Welcome. Click, a lot.</p>;

  return data.helloEntries.map(({ id, contentMain}) => (
    <div key={id}>
      {contentMain.map(content => <div className="flex flex-col space-y-4" key={content.id} dangerouslySetInnerHTML={{__html: content.text}} />) }
    </div>
  ));
}

function App() {
  return (
    <div className="bg-red-pantone min-h-screen flex flex-col justify-between">
      <div className="bg-white">
        <div className="max-w-4xl mx-auto flex justify-end lg:p-6 lg:px-8">
          <ToggleVisibility showLabel="Show skillset" hideLabel="Hide skillset">
            <GridContainer>
              <GridElement title="Frameworks">
                <p>Laravel, React, Unity3D</p>
              </GridElement>
              <GridElement title="Technologies">
                <p>PHP, JavaScript, .NET/C#</p>
              </GridElement>
              <GridElement title="Experience">
                <p>Graphic Communications</p>
                <p>Web Development</p>
                <p>Software Developer</p>
              </GridElement>
            </GridContainer>
          </ToggleVisibility>
        </div>
        <div className="max-w-4xl mx-auto">
          <h2 className="sr-only" id="profile-overview-title">Profile Overview</h2>
          <div className="p-3 lg:p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="mt-4 text-left lg:text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-2xl font-bold text-gray-900 sm:text-6xl">Joseph Preston</p>
                  <p className="text-xl font-medium text-gray-600">Software Developer | {process.env.NODE_ENV} environment</p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 lg:p-6">
            <Hello />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Clicker />
      </div>
    </div>
  );
}

export default App;
